import dayjs from 'dayjs';

export const archiveStateColor = (state) => {
  if (state === 'ACTIVE') return 'success';
  if (state === 'ARCHIVED') return 'danger';
};

export const projectTypeStateColor = (state) => {
  if (state === 'NEW_PROJECT') return 'primary';
  if (state === 'CLAIM_PROJECT') return 'yellow';
};

export const verificationStateColor = (state) => {
  if (state === 'UNAUDITED') return 'bg';
  if (state === 'REQUESTED') return 'warning';
  if (state === 'IN_PROGRESS') return 'primary';
  if (state === 'REJECTED') return 'danger';
  if (state === 'APPROVED') return 'success';
};

export const getApplicantStateColor = ($state) => {
  if ($state === 'NEW') return 'danger';
  if ($state === 'NOT_REACHED') return 'danger';
  if ($state === 'ACCEPTANCE') return 'success';
  if ($state === 'REJECTION') return 'danger';
  if ($state === 'FLYER_SENT') return 'danger';
  if ($state === 'CONSIDERATION_PERIOD') return 'danger';
  if ($state === 'INQUIRIES') return 'danger';
  if ($state === 'INTERVIEW') return 'success';
  if ($state === 'EMPLOYEE') return 'success';

  if ($state === 'TO_DEPARTMENT_AC') return 'success';
  if ($state === 'TO_DEPARTMENT_DC') return 'success';
  if ($state === 'AV_SENT') return 'success';
  if ($state === 'AV_COUNTERSIGNATURE') return 'success';
  if ($state === 'AV_TO_APPLICANT') return 'success';
  if ($state === 'AGREED_START_DATE') return 'success';
};

export const getTaskStateColor = ($state) => {
  if ($state === 'NEW') return 'warning';
  if ($state === 'RESOLVED') return 'success';
  if ($state === 'UNSOLVABLE') return 'danger';
  if ($state === 'IN_PROGRESS') return 'bg';
};
export const getAppointmentStateColor = ($state) => {
  if ($state === 'NEW') return 'warning';
  if ($state === 'SCHEDULED') return 'warning';
  if ($state === 'FINISHED') return 'success';
  if ($state === 'BACKLOG') return 'danger';
  if ($state === 'ALTERNATIVE_DATE') return 'bg';
  if ($state === 'TEAM_ON_SITE') return 'primary';
  if ($state === 'CANCELLATION') return 'danger';
};
export const getSalesPhaseColor = ($state) => {
  return 'inverse';
};
export const getSalesStateColor = ($state) => {
  return 'inverse';
};
export const getCallStateColor = ($state) => {
  return 'inverse';
};
export const getAppointmentTypeColor = ($state) => {
  if ($state === 'AC') return 'appointmentTypeA';
  if ($state === 'DC') return 'appointmentTypeB';
  if ($state === 'MEASUREMENT') return 'appointmentTypeC';
  if ($state === 'ELECTRIC_METER_CHANGE') return 'appointmentTypeD';
  if ($state === 'AC_DC') return 'appointmentTypeE';
  if ($state === 'MISC') return 'appointmentTypeF';
  if ($state === 'SERVICE_AC') return 'bg';
  if ($state === 'SERVICE_DC') return 'bg';
};
export const getAppointmentTypeHexColor = ($state) => {
  if ($state === 'AC') return '#5b96d6';
  if ($state === 'DC') return '#209e63';
  if ($state === 'MEASUREMENT') return '#ecc846';
  if ($state === 'ELECTRIC_METER_CHANGE') return '#c57021';
  if ($state === 'AC_DC') return '#d353c8';
  if ($state === 'MISC') return '#bba890';
  if ($state === 'SERVICE_AC') return '#bba890';
  if ($state === 'SERVICE_DC') return '#bba890';
};
export const getActiveStateColor = ($state) => {
  if ($state === 'ACTIVE') return 'success';
  if ($state === 'INACTIVE') return 'danger';
};
export const getYesNoStateColor = ($state) => {
  if ($state === 'YES') return 'success';
  if ($state === 'NO') return 'danger';
};
export const yesNoStateReversedColor = ($state) => {
  if ($state === 'YES') return 'danger';
  if ($state === 'NO') return 'success';
};
export const getRegistrationStateColor = ($state) => {
  if ($state === 'FINISHED') return 'success';
  if ($state === 'ERROR') return 'danger';
  if ($state === 'IN_PROGRESS') return 'warning';
  if ($state === 'DELETING') return 'warning';
};
export const getDocumentApprovedStateColor = ($state) => {
  if ($state === 'YES') return 'success';
  if ($state === 'NO') return 'danger';
  if ($state === 'IN_PROGRESS') return 'warning';
};
export const getProjectStateColor = ($state) => {
  return 'inverse';
};
export const getDocumentStateColor = ($state) => {
  if ($state === 'RECEIVED') return 'success';
  if ($state === 'MISSING') return 'danger';
  if ($state === 'INSUFFICIENT') return 'warning';
};

export const getTimeTrackingStateColor = ($state) => {
  if ($state === 'STARTED') return 'danger';
  if ($state === 'STOPPED') return 'success';
  if ($state === 'PAUSED') return 'warning';
};

export const getUserRoleColor = ($role) => {
  if ($role === 'ADMIN') return 'danger';
  if ($role === 'EMPLOYER') return 'warning';
  if ($role === 'TEAM_AC') return 'role-1';
  if ($role === 'TEAM_DC') return 'role-2';
  if ($role === 'STUDENT') return 'role-3';
  if ($role === 'GUEST') return 'role-4';
};
/**
 * choose a color for percentual progress (red to green)
 * @param $role
 * @returns {string}
 */
export const getProgressColor = (progressInPercent) => {
  if (progressInPercent >= 90) return 'success';
  if (progressInPercent >= 50) return 'primary';
  if (progressInPercent <= 15) return 'danger';
  if (progressInPercent <= 30) return 'warn';
  if (progressInPercent < 50) return 'yellow';
};

/**
 * return red if state != finished and date >= deadline
 * return yellow if state != finisehd and (date + 24h) >= deadline
 * @param date
 * @returns {string}
 */
export const getDeadlineColor = (deadline, state) => {
  if (state !== 'FINISHED' && dayjs().isAfter(dayjs(deadline))) return 'danger';
  if (state !== 'FINISHED' && dayjs().isAfter(dayjs(deadline).subtract(1, 'day'))) return 'yellow';

  return '';
};
