import gql from 'graphql-tag';

/**
 * Defines the fields which are requested for a project list on the mobile version
 * reduced fields only for list display!
 * @type {DocumentNode}
 */
export const PROJECT_LIST_MOBILE_REQUEST_FIELDS = gql`
  fragment projectPaginatedListFields on Project {
    id
    number
    clientProjectNumber
    deliveryAt
    projectState
    netRegistrationRequirementsFullfilledState
    netRegistrationSentAt
    operationRegistrationSentAt
    initialInformationSentToCustomerAt
    itemPlanningFinishedState
    technicalReview {
      dcInspectedState
      dcNotes
      acInspectedState
      acNotes
      additionalOrders
      additionalOrdersDispatchedState
      additionalOrdersDispatchedAt
      installationTeamNotes
      preliminaryExaminationSuccessfulState
      onSiteAppointmentProtocolUploadedToVlinkState
      preferredDeliveryInVlinkAt
      appointmentNotes
      dcTimeExpenditure
      acTimeExpenditure
    }
    posts {
      id
      number
      content
      createdAt
      updatedAt
      user {
        id
        number
        firstname
        lastname
        email
        avatar {
          path
        }
      }
    }
    employer {
      id
      number
      name
    }
    sales {
      state
      phase
      package
      inCharge {
        firstname
        lastname
      }
      usage
      kwhprice
      callState
      notes
    }
    customer {
      salutation
      company
      firstname
      lastname
      street
      streetNumber
      streetNumberSuffix
      zip
      city
      cityPart
      phone
      phone2
      phone3
      mobile
      mobile2
      email
      email2
      birthdayAt
      taxNumber
      bank
      iban
      bic
      accountHolder
      field
      fieldSector
      boundary
      electricMeterNumber
      electricMeterNumber2
      electricMeterNumber3
      electricMeterNumberDescription
      electricMeterNumber2Description
      electricMeterNumber3Description
      longitude
      latitude
      createdAt
      updatedAt
    }
    powerCompany {
      company
      _id
      number
    }
    teamDCAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    teamACAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    damageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    scaffoldAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    measurementAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    projectDescriptionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    solarModuleTypePlateAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installedComponentsInMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    openMeterCabinetPrePostAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    closedMeterCabinetInscribedComponentsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    closedPreMeterAreaWithPlombsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    breakthroughsWithSealingsMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    closedHAKWithSealAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    openHAKAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    photoOfWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    accessCardsOfWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    warrantyQCellsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    properSleeveUsedAtRoofPenetrationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    underlaySealedWellAtCableEntryAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    underlayDamagedOrWaterStainsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    labelingOfStringLinesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pvStringCableCorrectCrossSectionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    peCableCorrectlySizedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesProperlySecuredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    crimpingProfessionallyDoneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctSelectionOfPvConnectorsOnStringCableAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    allPlugConnectionsProfessionallyMadeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesNeatlyLaidInsideBuildingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    documentationProjectReportAvailableAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    specialCustomerRequestsDocumentedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    tileTypeAndWidthDocumentedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    replacementTileInstalledAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    lightningProtectionPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    substructureTensionFreeCheckedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    substructureInstalledAsPerReportAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    eachRailSecuredWithMinTwoHooksAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceBetweenHooksMoreThan140cmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctRoofHooksUsedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceRoofHookToTile35mmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    minScrewDistanceToRafterEdge24mmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofHookProperlyFixedToRafterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    overhangObservedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    moduleFieldProperlyGroundedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    approvedAuxiliaryRafterUsedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    rafterWidthMeasuredAndComparedToDocsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    noOpenOrDamagedTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    noShiftedOrIncorrectlyLaidTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    tilesProperlyCleanedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctPanHeadScrewsUsedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    minRailOverhangAfterEndClamp20mmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distancesToRidgeEavesNotLessThan20cmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    moduleClampAreasObservedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    modulesNoDamagesDirtShadingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    moduleClampsProperlyTightenedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    thermalSeparationAfter12mModuleFieldAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    breakthroughProperlySealedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceLightningProtectionToConductivePartsLessThan05mAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    gutterUndamagedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    notBuiltOverRoofOverhangAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableProperlyLaidInChimneyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceToDisturbingSurfaces10cmObservedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableEntryWithReplacementPlateAndFlexedTileAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesDoNotRestOnTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesLaidProtectedUnderTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableEntryOnRoofProperlyDoneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableLaidThroughChimneyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableLaidInProtectiveSleeveAgainstUvRadiationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    peCableProperlySecuredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    documentationProjectReportPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    customerSpecialRequestsConsideredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRooflightningProtectionPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    properGroundingFlatRoofSystemAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ballastingAccordingToProjectReportAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    minimumModuleBearingAreaMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    qFlatProperlyConnectedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceToDisturbingSurfacesMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRoofmoduleClampsProperlyTightenedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    modulesNoDamageDirtShadingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    typeOfCableProtectionOutdoorsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctSelectionOfPvConnectorsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesDoNotRestOnRoofAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableEntryDoneProperlyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesProtectedFromUvAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pvStringCableProperlySizedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringCableLabelingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    peCableProperlySizedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRoofcablesProperlySecuredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    crimpingProperlyDoneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pvSystemLabeledInOrAtDistributionBoardAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    testReportAvailableWithAllMeasurementsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    rcdTypeB300maAvailableInMeterAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringPlanFiledInMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    safetyDistancesMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctLocationForSystemAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctLocationForWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    customerRequestsDocumentedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    slsInstalledAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    componentsDamagedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    crimpingDoneProperlyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ussWiredProperlyInCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ussBoxWiredProperlyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    bendingRadiiMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctWiringOfMateboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctWiringOfBatteryAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    allComponentsProperlyGroundedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctColorCodingAndLabelingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctWiringOfPvConnectorsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableDuctProperlySizedAndCutAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    nhHakSelectiveToSlsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    checkNetworkTypeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    earthRodConnectedToHpasAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    connectionBetweenHpasAndPasProperlyMadeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    insulationProperlyStrippedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    polarityCheckAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringOpenCircuitVoltageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    shortCircuitCurrentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    insulationResistanceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    acceptanceProtocolCustomerSignatureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    circuitBreakerAndSafetySwitchForWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablingBetweenWallboxAndDistributionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    openMateboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    variousBreakthroughsWithPossibleSealingsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableManagementBetweenMateboxAndMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installationImageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    brickAndSubstructureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    solarPanelAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableManagementAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    equipotentialBusBarAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    inverterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    storageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    systemPowerOnAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterChangeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installation {
      installationAppointments {
        installationTeam
        appointmentApprovedState
        requiredInstallationTimeinHours
        installationAt
        installationType
        calendarWeek
      }
      description
      electricMeterChangedAt
      originalInstallationType
    }
    solarPlant {
      guaranteeCode
      extendedCode
      systemNumber
      yearOfConstruction
      solarPanelCount
      solarPanel {
        id
        number
        manufacturer
        type
        power
      }
    }
    enpalReport {
      signatureApprovalCustomer
      signatureApprovalTechnician
      signatureOperationCustomer
      signatureOperationTechnician
      scaffoldHeightInMeters
      scaffoldAttachment {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
    }
  }
`;
