import gql from 'graphql-tag';

/**
 * Defines the fields which are requested for a project list
 * reduced fields only for list display!
 * @type {DocumentNode}
 */
export const PROJECT_PAGINATED_LIST_REQUEST_FIELDS = gql`
  fragment projectPaginatedListFields on Project {
    id
    number
    createdAt
    clientProjectNumber
    employerProjectNumber
    deliveryAt
    projectState
    projectTypeState
    askEmployerCategoryState
    netRegistrationRequirementsFullfilledState
    operationRegistrationRequirementsFullfilledState
    netRegistrationSentAt
    operationRegistrationSentAt
    initialInformationSentToCustomerAt
    itemPlanningFinishedState
    consumablesInStockState
    technicalReviewDescription
    deferredState {
      _id
      deferred
      reason
      description
      comment
      createdAt
      updatedAt
    }
    onHoldState {
      _id
      onHold
      reason
      description
      comment
      createdAt
      updatedAt
    }
    newestInstallationAppointment {
      installationStartAt
    }
    newestMeasurementAppointment {
      installationStartAt
    }
    latestInstallationAppointment {
      installationTeam
      appointmentApprovedState
      requiredInstallationTimeinHours
      installationAt
      installationType
      calendarWeek
    }
    employer {
      id
      number
      name
    }
    client {
      id
      number
      name
    }
    customer {
      salutation
      company
      firstname
      lastname
      street
      streetNumber
      streetNumberSuffix
      zip
      city
      cityPart
      phone
      phone2
      phone3
      mobile
      mobile2
      email
      email2
      birthdayAt
      taxNumber
      bank
      iban
      bic
      accountHolder
      field
      fieldSector
      boundary
      electricMeterNumber
      electricMeterNumber2
      electricMeterNumber3
      electricMeterNumberDescription
      electricMeterNumber2Description
      electricMeterNumber3Description
      longitude
      latitude
      createdAt
      updatedAt
    }
    sales {
      phase
      state
      package
      inCharge {
        firstname
        lastname
      }
      usage
      kwhprice
      callState
      notes
    }
    powerCompany {
      company
      _id
      number
      netRegistrationDocumentsApprovedState
      operationRegistrationDocumentsApprovedState
    }
    installation {
      installationAppointments {
        installationTeam
        appointmentApprovedState
        requiredInstallationTimeinHours
        installationAt
        installationType
        calendarWeek
      }
      description
      electricMeterChangedAt
      originalInstallationType
    }
    solarPlant {
      guaranteeCode
      extendedCode
      systemNumber
      yearOfConstruction
      solarPanelCount
      solarPlantOverallPower
      solarPanel {
        id
        number
        manufacturer
        type
        power
      }
    }
  }
`;
