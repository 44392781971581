import gql from 'graphql-tag';
import apolloClient from '../apollo';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import 'dayjs/locale/de';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.locale('de');

/**
 * State
 */
const defaultTask = () => {
  return {
    id: '_new',
    number: '_new',
    taskState: 'NEW',
    project: null,
    inCharge: null,
  };
};

const getDefaultState = () => {
  return {
    tasks: [],
    newTasks: [],
    userTasks: [],
    taskCount: 0,
    newTaskCount: 0,
    userTaskCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an task
 * used for all queries/mutations which return an task
 * @type {DocumentNode}
 */
const TASK_REQUEST_FIELDS = gql`
  fragment taskFields on Task {
    id
    number
    taskState
    description
    notes
    project {
      id
      number
      employerProjectNumber
      customer {
        firstname
        lastname
        street
        streetNumber
        zip
        city
        email
      }
    }
    inCharge {
      id
      number
      firstname
      lastname
    }
    createdAt
    updatedAt
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} task
 */
const createPayload = (task) => {
  const payload = {
    ...(task.taskState ? { taskState: task.taskState } : {}),
    ...(task.project ? { project: task.project.id } : {}),
    ...(task.inCharge ? { inCharge: task.inCharge.id } : {}),
    description: task.description,
    notes: task.notes,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchTasks(
    { commit, dispatch },
    { page, pageSize, sortField, sortOrder, filters, taskListType = 'TASKS' }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query tasks(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: TaskFilterInput
          ) {
            tasks(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              tasks {
                ...taskFields
              }
              taskCount
            }
          }
          ${TASK_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      if (taskListType === 'NEW_TASKS') {
        commit('SET_NEW_TASKS', response.data.tasks.tasks);
        commit('SET_NEW_TASK_COUNT', response.data.tasks.taskCount);
      } else if (taskListType === 'USER_TASKS') {
        commit('SET_USER_TASKS', response.data.tasks.tasks);
        commit('SET_USER_TASK_COUNT', response.data.tasks.taskCount);
      } else {
        commit('SET_TASKS', response.data.tasks.tasks);
        commit('SET_TASK_COUNT', response.data.tasks.taskCount);
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async createTask({ commit, dispatch }, task) {
    const payload = createPayload(task);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createTask($payload: TaskInput!) {
            createTask(taskInput: $payload) {
              ...taskFields
            }
          }
          ${TASK_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Aufgabe gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_TASK', '_new'); // remove temporary _new task
      commit('ADD_OR_UPDATE_TASK', response.data.createTask);
      return response.data.createTask.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateTask({ commit, dispatch }, task) {
    const payload = createPayload(task);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateTask($taskNumber: Int!, $payload: TaskInput!) {
            updateTask(taskNumber: $taskNumber, taskInput: $payload) {
              ...taskFields
            }
          }
          ${TASK_REQUEST_FIELDS}
        `,
        variables: {
          taskNumber: task.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Aufgabe aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_TASK', response.data.updateTask);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteTask({ commit, dispatch }, taskNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteTask($taskNumber: Int!) {
            deleteTask(taskNumber: $taskNumber)
          }
        `,
        variables: {
          taskNumber: taskNumber,
        },
      });
      dispatch('message', {
        message: 'Aufgabe gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      // console.log(response.data.deleteTask);

      commit('REMOVE_TASK', taskNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async sendTaskMail({ commit, dispatch }, { taskNumber }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation sendTaskMail($taskNumber: Int!) {
            sendTaskMail(taskNumber: $taskNumber) {
              ...taskFields
            }
          }
          ${TASK_REQUEST_FIELDS}
        `,
        variables: {
          taskNumber: taskNumber,
        },
      });
      dispatch('message', {
        message: 'Nachricht verschickt!',
        type: 'success',
        lifetime: 1,
      });

      commit('ADD_OR_UPDATE_TASK', response.data.sendTaskMail);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Versand!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new task object in store and return it
   * @param {*} param0
   * @param {*} task
   */
  async initTask({ commit, state }) {
    const newTask = defaultTask();
    commit('ADD_OR_UPDATE_TASK', newTask);
  },

  /**
   * create a new task with existing data
   * @param {*} param0
   * @param {*} task
   */
  async duplicateTask({ commit }, { task, installationStartAt }) {
    const duplicate = Object.assign(defaultTask(), {
      taskType: task.taskType,
      project: task.project,
      employees: task.employees,
      description: task.description,
      installationStartAt: installationStartAt,
      installationEndAt: dayjs(installationStartAt)
        .add(8, 'hour')
        .toDate(),
    });

    // console.log(duplicate);
    commit('ADD_OR_UPDATE_TASK', duplicate);
    return duplicate;
  },

  taskLocalUpdate({ commit }, task) {
    commit('ADD_OR_UPDATE_TASK', task);
  },

  taskCleanup({ commit }) {
    commit('REMOVE_TASK', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TASK_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TASKS: (state, tasks) => (tasks ? (state.tasks = tasks) : (state.tasks = [])),
  SET_NEW_TASKS: (state, tasks) => (state.newTasks = tasks || []),
  SET_USER_TASKS: (state, tasks) => (state.userTasks = tasks || []),
  ADD_TASK: (state, task) => state.tasks.push(task),
  ADD_OR_UPDATE_TASK: (state, updatedTask) => {
    const existingTask = state.tasks.find((task) => task.id === updatedTask.id);
    if (existingTask) {
      Object.assign(existingTask, updatedTask);
    } else {
      state.tasks.unshift(updatedTask);
    }
  },
  REMOVE_TASK: (state, taskNumber) => {
    // console.log(taskNumber);
    const index = state.tasks.findIndex((task) => task.number === taskNumber);
    state.tasks.splice(index, 1);
  },
  SET_TASK_COUNT: (state, taskCount) => (state.taskCount = taskCount),
  SET_NEW_TASK_COUNT: (state, taskCount) => (state.newTaskCount = taskCount),
  SET_USER_TASK_COUNT: (state, taskCount) => (state.userTaskCount = taskCount),
};

/**
 * Getters
 */
const getters = {
  getTasks: (state) => state.tasks,
  getNewTasks: (state) => state.newTasks,
  getUserTasks: (state) => state.userTasks,
  getTask: (state) => (taskId) => state.tasks.find((task) => task.id == taskId),
  getTaskByNumber: (state) => (taskNumber) => state.tasks.find((task) => task.number == taskNumber),
  getTaskCount: (state) => state.taskCount,
  getNewTaskCount: (state) => state.newTaskCount,
  getUserTaskCount: (state) => state.userTaskCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
