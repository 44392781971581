<template>
  <div>
    <b-sidebar
      v-if="user"
      id="sidebar-mobile"
      :title="displayName"
      shadow
      backdrop
      backdrop-variant="dark"
    >
      <div class="px-3 py-2">
        <p>
          {{ $t(getCurrentUser.role) }}
        </p>
      </div>
      <div class="px-3 py-2">
        <p>
          <a href="#" @click.prevent="logout" class="">
            <i class="fa fa-sign-out" />
            Abmelden
          </a>
        </p>
      </div>
      <footer class="mobile-footer">Goaltip GmbH - v.{{ version }}</footer>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MobileSidebar',
  props: {
    user: null,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser']),
    displayName() {
      return this.user.firstname + ' ' + this.user.lastname;
    },
  },
  methods: {
    ...mapActions('auth', ['logoutUser', 'refreshUser']),
    async logout() {
      await this.logoutUser();
      await this.$router.push('/login');
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-footer {
  position: absolute;
  bottom: 5px;
  text-align: center;
  width: 100%;
  color: $gray-600;
  font-size: 1rem;
}
</style>
