import { reactive } from 'vue';

class TableState {
  name = null;
  pagination = {};
  sorting = {};
  filters = {};
  customFilters = {};
  options = null;

  constructor(options) {
    this.name = options.name;
    this.options = options;
    this.setup();
    console.log('TableState::constructor()', this.name, options);
  }

  setup() {
    this.filters = this.options.filters ? this.options.filters : defaultFilters();
    this.customFilters = this.options.customFilters
      ? this.options.customFilters
      : defaultCustomFilters();
    this.pagination = this.options.pagination ? this.options.pagination : defaultPagination();
    this.sorting = this.options.sorting ? this.options.sorting : defaultSorting();
  }

  /**
   * Merge custom filter input to the stored customFilters and save to localStorage
   * @param {*} customFilterInput
   */
  setCustomFilters(customFilterInput) {
    console.log('TableState::setCustomFilters()', customFilterInput);
    this.customFilters = { ...this.customFilters, ...customFilterInput };
    localStorage.setItem(this.customFilterName(), JSON.stringify(this.customFilters));
  }

  /**
   * Remove all filters and sorting from localstorage and apply default values
   */
  reset() {
    console.log('TableState::reset()', this.options);
    localStorage.removeItem(this.filterName());
    localStorage.removeItem(this.customFilterName());
    this.setup();
  }

  /**
   * setup tablestate from localstorage and provided tableStateData
   */
  restore(restoreEventData) {
    console.log('TableState::restore()', restoreEventData);

    this.sorting = {
      sortField: restoreEventData.sortField
        ? restoreEventData.sortField
        : defaultSorting().sortField,
      sortOrder: restoreEventData.sortOrder
        ? restoreEventData.sortOrder
        : defaultSorting().sortOrder,
    };
    this.pagination = {
      page: defaultPagination().page,
      pageSize: restoreEventData.rows ? restoreEventData.rows : defaultPagination().pageSize,
    };

    const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.customFilterName()));
    this.customFilters = customFiltersFromStorage
      ? customFiltersFromStorage
      : defaultCustomFilters();
  }

  skip() {
    return this.pagination.pageSize * this.pagination.page;
  }

  filterName() {
    return `${this.name}-table-filters`;
  }

  customFilterName() {
    return `${this.name}-custom-table-filters`;
  }
}

const defaultSorting = () => {
  return { sortField: 'createdAt', sortOrder: -1 };
};
const defaultPagination = () => {
  return { page: 0, pageSize: 10 };
};
const defaultFilters = () => {
  return {};
};
const defaultCustomFilters = () => {
  return {};
};

/**
 * State
 */
const getDefaultState = () => {
  return {
    tableStates: [],
  };
};
const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  /**
   * create a new tableState object in store and return it
   * @param {*} param0
   * @param {*} tableState
   */
  async initTableState({ commit }, tableStateOptions) {
    commit('ADD_OR_UPDATE_TABLE_STATE', reactive(new TableState(tableStateOptions)));
  },
};

/**
 * Mutations
 */
const mutations = {
  ADD_OR_UPDATE_TABLE_STATE: (state, updatedTableState) => {
    const existingTableState = state.tableStates.find(
      (tableState) => tableState.name === updatedTableState.name
    );
    if (existingTableState) {
      Object.assign(existingTableState, updatedTableState);
    } else {
      state.tableStates.push(updatedTableState);
    }
  },
};

/**
 * Getters
 */
const getters = {
  getTableState: (state) => (tableStateName) =>
    state.tableStates.find((tableState) => tableState.name == tableStateName),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
