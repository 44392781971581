<template>
  <div>
    <b-navbar toggleable type="dark" variant="dark">
      <b-navbar-brand href="#">
        <img class="mobile-logo" src="../../assets/retis_small.png" alt="Q Power Solution GmbH" />
      </b-navbar-brand>

      <b-navbar-nav class="mobile-nav">
        <b-nav-text> <LoadingSuccessSpinner /></b-nav-text>
      </b-navbar-nav>

      <b-navbar-nav>
        <Dropdown
          v-model="selectedLanguage"
          :options="languages"
          optionLabel="label"
          @change="onLanguageChange()"
          :placeholder="$t('language')"
        />
      </b-navbar-nav>

      <b-navbar-toggle target="sidebar-mobile">
        <template>
          <span class="avatar rounded-circle thumb-sm">
            <img
              v-if="user && user.avatar"
              class="rounded-circle"
              :src="user.avatar.path"
              alt="..."
            />
            <span v-else>{{ firstUserLetter }}</span>
          </span>
        </template>
      </b-navbar-toggle>
    </b-navbar>

    <MobileSidebar :user="user" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MobileSidebar from '@/components/Sidebar/MobileSidebar.vue';
import LoadingSuccessSpinner from '@/components/LoadingSuccessSpinner.vue';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'MobileHeader',
  components: { LoadingSuccessSpinner, MobileSidebar, Dropdown },
  data() {
    return {
      selectedLanguage: { code: 'de', label: this.$t('de') },
      languages: [
        { code: 'de', label: this.$t('de') },
        { code: 'en', label: this.$t('en') },
        { code: 'it', label: this.$t('it') },
        { code: 'cs', label: this.$t('cs') },
        { code: 'sk', label: this.$t('sk') },
        { code: 'bs', label: this.$t('bs') },
        { code: 'hr', label: this.$t('hr') },
        { code: 'ro', label: this.$t('ro') },
        { code: 'hu', label: this.$t('hu') },
        { code: 'pl', label: this.$t('pl') },
        { code: 'bg', label: this.$t('bg') },
        { code: 'ru', label: this.$t('ru') },
        { code: 'ko', label: this.$t('ko') },
        { code: 'fa', label: this.$t('fa') },
      ],
    };
  },
  computed: {
    ...mapState('layout', [
      'sidebarClose',
      'sidebarStatic',
      'chatNotificationIcon',
      'chatNotificationPopover',
    ]),
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters(['getUser']),
    firstUserLetter() {
      if (this.user) {
        return (this.user.firstname || this.user.email)[0].toUpperCase();
      }
      return '';
    },
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
  },
  methods: {
    ...mapActions('layout', ['initApp']),
    ...mapActions('auth', ['refreshUser']),
    /**
     * change display language
     */
    async onLanguageChange() {
      if (this.user.id) {
        this.user.locale = this.selectedLanguage.code;
        this.$i18n.locale = this.selectedLanguage.code;
        localStorage.setItem('locale', this.selectedLanguage.code);
        console.log(this.selectedLanguage);
        // await this.updateUser(this.user);
      }
    },
    async reload() {
      await this.refreshUser();
    },
  },
  created() {
    if (window.innerWidth > 576) {
      this.initApp();
    }
  },
  mounted() {
    this.selectedLanguage = { code: this.$i18n.locale, label: this.$t(this.$i18n.locale) };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0 0.5em;
}
.navbar-nav {
  flex-direction: row;
}
.navbar-toggler {
  border: 0;
}
.mobile-nav {
  margin: 0 auto;
}
.mobile-logo {
  height: 25px;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 28px;
  width: 28px;
  background: $retis-purple;
  font-weight: bold;
  font-size: 17px;
  position: relative;
  color: $white;
}
a.active {
  text-decoration: underline;
}
</style>
